












































































import {Component, Prop, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import PhoneInput from "@/components/util/PhoneInput.vue";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import AdminLinksModal from "@/components/profile/admin/AdminLinksModal.vue";
import {fromCompany} from "@/dto/auth/Workspace";
import CustomerAccounts from "@/dto/CustomerAccounts";
import MergeAccountModal from "@/components/profile/admin/MergeAccountModal.vue";
import CompanyDTO from "@/dto/company/CompanyDTO";
import CreateCompanyRequestDTO from "@/dto/company/CreateCompanyRequestDTO";
import CompanyService from "@/services/CompanyService";
import {AccountType} from "@/constants/AccountTypes";
import MergeCompanyEinModal from "@/components/profile/admin/company/MergeCompanyEinModal.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import {ifValid} from "@/utils/ComponentUtils";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
	components: {PortalCheckbox, PortalInput, PhoneInput}
})
export default class CompanyCreationModal extends Vue {
	private message = "";

	private successful = false;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	@Prop()
	private onSuccess!: () => void

	private request = new CreateCompanyRequestDTO()

  private shouldNotCreateAccount = false


	get isPhoneFilled(){
		return (this.request.phone != undefined || this.request.phone != null)
			&& this.request.phone != "" && this.request.phone.length >= 7;
	}

	closeModal(){
		this.$modal.hideAll();
	}

	handleSuccess() {
		this.onSuccess();
		this.stopLoading();
		this.closeModal();
	}

	save() {
		ifValid(this, () => {
			this.startLoading()
			if (this.request.phone != null) {
				if (7  > this.request.phone.length) {
					this.request.phone = null;
				}
			}
			this.message = ""
			this.request.email = this.request.email?.trim() == '' ? null : this.request.email;
			CompanyService.registerCustomer(this.request).then(
				() => this.handleSuccess(),
				err => {
					this.errorHandling(err)
				}
			)
		})
	}

	errorHandling(err: any) {
		this.successful = false;
		let systemCode = err?.response?.data.systemCode;
		let param = err?.response?.data.param;
		let company: CompanyDTO;
		let customerAccounts: CustomerAccounts;
		switch (systemCode) {
			case 40024:
			case 40033:
				company = param;
				if (company.email == null && this.request.email != null) {
					this.openMergeCompanyEinModal(company);
				} else {
					this.openLinksModal(company);
				}
				this.message = this.$t(ErrorLocalePathUtils.getPathForError(err), {name: company.name, ein: company.ein, tin: company.nationalTin}) as string;
				break;
			case 40025:
				customerAccounts = param;
				this.openMergeAccountModal(customerAccounts)
				this.message = this.$t(ErrorLocalePathUtils.getPathForError(err),
					{
						lastName: customerAccounts.person?.lastName,
						firstName: customerAccounts.person?.firstName,
						listCompanyNames: this.handleCompaniesName(customerAccounts.companies)
					}) as string;
				break
			default:
				this.message = this.$t(ErrorLocalePathUtils.getPathForError(err), param) as string;
		}
		this.stopLoading();
	}

	handleCompaniesName(companies: Array<CompanyDTO>): string {
		let res = "";
		for (let i = 0; i < companies.length; i++) {
			res += companies[i].name.concat(i == companies.length - 1 ? "" : ", ");
		}
		return res;
	}

	openMergeCompanyEinModal(company: CompanyDTO) {
		this.$modal.show(
			MergeCompanyEinModal,
			{
				existCompany: company,
				request: this.request,
				onSuccess: () => this.handleSuccess()
			}
		)
	}

	openMergeAccountModal(customerAccounts: CustomerAccounts) {
		this.$modal.show(
			MergeAccountModal,
			{
				customerAccounts: customerAccounts,
				companyReq: this.request,
				mode: AccountType.COMPANY,
				onSuccess: () => this.handleSuccess()
			}
		);
	}

	openLinksModal(company: CompanyDTO) {
		this.$modal.show(
			AdminLinksModal,
			{
				workspace: fromCompany(company),
				name: company.name,
				companyReq: this.request
			}
		);
	}
}
